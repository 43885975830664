import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    value: _ctx.$attrs.value,
    rules: _ctx.$attrs.rules,
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help,
    "validate-first": _ctx.validateFirst,
    "validate-trigger": _ctx.$attrs.validateTrigger
  }, _createSlots({
    labelEnd: _withCtx(() => [
      _renderSlot(_ctx.$slots, "labelEnd")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_input, _mergeProps(_ctx.$attrs, {
        ref: "inputRef",
        autocomplete: "disabled",
        "on-focus": _ctx.focusHandler,
        "on-blur": _ctx.blurHandler,
        autocorrect: "disabled",
        autocapitalize: "disabled",
        spellcheck: "false"
      }), {
        prefix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "prefix")
        ]),
        suffix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "suffix")
        ]),
        addonAfter: _withCtx(() => [
          _renderSlot(_ctx.$slots, "addonAfter")
        ]),
        _: 3
      }, 16, ["on-focus", "on-blur"])
    ]),
    _: 2
  }, [
    (_ctx.$slots['extra'])
      ? {
          name: "extra",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "extra")
          ])
        }
      : undefined
  ]), 1032, ["label", "name", "value", "rules", "extra", "help", "validate-first", "validate-trigger"]))
}