import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, _mergeProps({
    type: _ctx.type,
    "html-type": "submit",
    class: "submit-button"
  }, _ctx.$attrs), {
    icon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.t("Continue")), 1)
      ])
    ]),
    _: 3
  }, 16, ["type"]))
}