
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    // Default is primary
    type: {
      type: String,
      default: "primary",
    },
  },
  setup() {
    const t = useI18n().t;

    return { t };
  },
});
