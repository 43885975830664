import { Rules } from "async-validator";

export const hasDigit = (value: string) => {
  const digit = new RegExp(/[0-9]/);
  return digit.test(value);
};

export const hasSpecialChars = (value: string) => {
  const specialChars = new RegExp(/[\]"'[\\,!@#$%^&*?/:;<>`~}{|)(+=._-]/);
  return specialChars.test(value);
};

export const hasUpperCase = (value: string) => {
  const upperCase = new RegExp(/[A-Z]/);
  return upperCase.test(value);
};

export const hasLowerCase = (value: string) => {
  const lowerCase = new RegExp(/[a-z]/);
  return lowerCase.test(value);
};

export const hasLatinChars = (value: string) => {
  const latinChars = new RegExp(/[a-zA-Z0-9]/);
  return latinChars.test(value);
};

export const hasMinimumChars = (value: string, minimumChars = 8) => {
  return value.length >= minimumChars;
};

export const hasMaximumChars = (value: string, maximumChars = 32) => {
  return value.length <= maximumChars;
};

export const makePasswordRule = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        if (
          hasLatinChars(value) &&
          hasUpperCase(value) &&
          hasLowerCase(value) &&
          hasMinimumChars(value) &&
          hasDigit(value)
        ) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
  };
};

export const makeRepeatPasswordRule = (
  password: string,
  requiredErrorMsg: string,
  unmatchPasswordErrorMsg: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      if (value === "") {
        return Promise.reject(requiredErrorMsg);
      } else if (value !== password) {
        return Promise.reject(unmatchPasswordErrorMsg);
      } else {
        return Promise.resolve();
      }
    },
  };
};

export const makeNewPasswordRule = (
  oldPassword: string,
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  type: string;
} => {
  return {
    type: "string",
    asyncValidator: (rule, value) => {
      if (value == oldPassword) {
        return Promise.reject(errorMessage);
      } else {
        return Promise.resolve();
      }
    },
  };
};
