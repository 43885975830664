import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "label-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, {
    label: _ctx.label,
    colon: false,
    rules: _ctx.rules,
    name: _ctx.$attrs.name
  }, _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "labelEnd")
      ])
    ]),
    _: 2
  }, [
    (_ctx.$slots['extra'])
      ? {
          name: "extra",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "extra")
          ])
        }
      : undefined
  ]), 1032, ["label", "rules", "name"]))
}