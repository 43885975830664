import { i18nTranslate } from "@/plugins/i18n";
import { Rules } from "async-validator";
import validator from "multicoin-address-validator";
import { hasMaximumChars, hasMinimumChars } from "./passwordValidator";

export const makeWalletAddressValidator = (
  errorMessage: string
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return {
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        const isValid = validator.validate(value, "ada");
        if (isValid) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
    message: errorMessage,
  };
};

export const makeStakingIdValidator = (
  errorMessage = i18nTranslate("Staking Key Hash is Invalid")
): {
  asyncValidator: (rule: Rules, value: string) => Promise<void>;
  message: string;
} => {
  return {
    asyncValidator: (rule, value) => {
      return new Promise<void>((resolve, reject) => {
        if (hasMinimumChars(value, 56) && hasMaximumChars(value, 56)) {
          resolve();
        } else {
          reject(new Error(errorMessage));
        }
      });
    },
    message: errorMessage,
  };
};
